import {withRouter} from "react-router-dom";
import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Button, Input, Spin, Tooltip} from 'antd';
import {useSelector, useDispatch} from "react-redux";
import {sleep} from "../../utils/utilfunc"
import axios from "axios";
import Swal from 'sweetalert2';
import MyAudioRecorder from "../../utils/MyAudioRecorder";
import {USER_SERVER} from '../../../config'
import {removeCookie} from "../../../cookie/cookie";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Chat(props) {
    let user = useSelector((state) => state.user);
    const vrecorder = useRef(null);
    const audioRef = useRef(new Audio());
    const UserInfo = user.userData;     // UserInfo={token, user_id}

    const [HistoryList, setHistoryList] = useState([]);
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const [Question, setQuestion] = useState('');
    const [RecStatus, setRecStatus] = useState('ready');			// ready, prepare_rec, rec, prepare_stop
    const [MicMode, setMicMode] = useState(false);
    const [PaymentParking, setPaymentParking] = useState({pay: false});
    const [Mp3Url, setMp3Url] = useState(null);
    const [TYPECAST_TTS_TOKEN, setTYPECAST_TTS_TOKEN] = useState('');
    const [TooltipOpen, setTooltipOpen] = useState(false);
    const [WhilePlay, setWhilePlay] = useState(false);

    useEffect(() => {
        get_chat_history();
        get_typecast_token();
    }, [UserInfo]);

    useEffect(() => {
        const handleEnded = () => {
            setWhilePlay(false);
        };

        // url이 null이 아닐 때만 재생
        if (Mp3Url) {
            setWhilePlay(true);
            audioRef.current.src = Mp3Url;
            audioRef.current.play().catch(error => console.log("재생 중 오류 발생:", error));
            audioRef.current.addEventListener('ended', handleEnded);
        }

        // Clean up the event listener when the component unmounts or Mp3Url changes
        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('ended', handleEnded);
            }
        };
    }, [Mp3Url]); // url 상태가 변경될 때마다 이 효과를 실행


    async function get_chat_history() {
        if (!UserInfo?.token)
            return;

        const res = await axios.post(`${USER_SERVER}/get_chat_history`, {
            user_id: UserInfo.user_id,
            token: UserInfo.token
        });
        console.log('get_chat_history--------->', res);
        if (res.data?.success) {
            setHistoryList(res.data.data);
        } else {
            show_error(res);
        }
    }

    async function get_typecast_token() {
        if (!UserInfo?.token)
            return;

        const res = await axios.post(`${USER_SERVER}/get_typecast_token`, {
            user_id: UserInfo.user_id,
            token: UserInfo.token
        });
        if (res.data?.success) {
            setTYPECAST_TTS_TOKEN(res.data.data);
        } else {
            show_error(res);
        }
    }

    // error 나면 에러 정보를 디스플레이
    function show_error(res) {
        const tdata = res.data.data;
        if (typeof tdata === 'object' && 'title' in tdata) {
            Swal.fire({
                title: res.data.data.title,
                text: res.data.data.description,
                icon: 'info',
                confirmButtonText: '확인'
            });
            if (tdata.type == 'tokenExpired') {
                removeCookie('w_auth');
                props.history.push("/login");
            }
        } else {
            Swal.fire({
                title: 'Error',
                text: res.data.data,
                icon: 'error',
                confirmButtonText: '확인'
            });
        }
    }

    async function onClickSend() {
        console.log('onClickSend');
        if (Img) {
            await send_question_with_image(Question, Img);
        } else {
            await send_question(Question);
        }
    }

    async function send_question_with_image(question, img) {
        console.log("send_question_with_image");
        setWaitAnswer(true);
        const formData = new FormData();
        formData.append('image', img);
        formData.append('question', question.trim());
        formData.append('user_id', UserInfo.user_id);
        formData.append('token', UserInfo.token);
        formData.append('tts', false);
        formData.append('status', JSON.stringify({
            hw_state: props.HwState
        }));

        const res = await axios.post(`${USER_SERVER}/get_answer`, formData, {headers: {"Content-Type": "multipart/form-data"}});
        URL.revokeObjectURL(ImgUrl);
        setImgUrl("")
        setImg(null);
        setWaitAnswer(false);
        console.log('get_answer--------->', res);
        if (res.data?.success) {
            await process_answer(res.data.data);
        } else {
            show_error(res);
        }
    }

    async function send_question(question) {
        setWaitAnswer(true);
        const currentDate = new Date();
        const dataToCommit = {
            token: UserInfo.token,
            user_id: UserInfo.user_id,
            question: question.trim(),
            tts: false,
            status: {hw_state: props.HwState},
        }

        const res = await axios.post(`${USER_SERVER}/get_answer`, dataToCommit);
        setWaitAnswer(false);
        console.log('get_answer--------->', res);
        if (res.data?.success) {
            await process_answer(res.data.data);
        } else {
            show_error(res);
        }
    }

    async function process_answer(data) {
        const question = data.question;
        const answer = data.answer;
        const control_object = data.control;
        const user_msg = {speaker: 'h', message: question, image: data.image};
        const assistant_msg = {speaker: 'c', message: answer};
        const role = data?.role;
        setHistoryList([...HistoryList, user_msg, assistant_msg]);

        // role이 clova이면 처리
        if (role == 'clova') {
            control_object.clova = 'true';
        } else {
            control_object.clova = 'false';
        }

        // hw state를 변경하는 요청이 오면 처리
        if (Object.keys(control_object).length > 0) {
            console.log('change_Hw_state', control_object);
            props.change_Hw_state(control_object);
        }


        if (data?.function) {
            const tstr = JSON.stringify(data.function);
            const newstr = tstr.replace(/["\\"]/g, '');
            if (newstr !== '{}') {                 // empty 이면 추가 안함
                assistant_msg.gptfunc = newstr;
            }
        }

        if (data?.tts_url) {
            await get_tts_audio_url(data.tts_url);
        }
    }

    // typecast tts가 완료되었는지 체크하고 완료되었으면 mp3의 url을 리턴
    async function get_tts_audio_url(speak_url) {
        const HEADERS = {'Authorization': `Bearer ${TYPECAST_TTS_TOKEN}`};

        for (let k = 0; k < 30; k++) {
            const res = await axios.get(speak_url, {headers: HEADERS});
            console.log('get_tts_audio_url--------->', res);
            if (res.data.result) {
                const ret = res.data.result;
                if (ret.status == 'done') {
                    const mp3_url = res.data.result.audio_download_url;
                    setMp3Url(mp3_url);
                    console.log('delay=', k * 300);
                    return;
                }
            }
            await sleep(300);
        }
    }

    async function onClickReset() {
        setWaitAnswer(true);
        const res = await axios.post(`${USER_SERVER}/reset_history`, {
            token: UserInfo.token,
            user_id: UserInfo.user_id
        });
        console.log('reset_history--------->', res);
        if (res.data?.success) {
            await get_chat_history();
        } else {
            alert('Error:' + res.data.data);
        }
        setWaitAnswer(false);
    }

    useEffect(() => {
        if (document) {
            const divEl = document.getElementById("hide-scroll");
            divEl.scrollTo({behavior: "smooth", top: divEl.scrollHeight});
        }
    }, [HistoryList]);

    const render_histlist = HistoryList && HistoryList.map(function (hist, index) {
        const prompt = hist.speaker === 'h' ? 'User:  ' : 'Assistant:  ';
        const func_msg = (hist?.gptfunc) ? '  (' + hist.gptfunc + ')' : '';

        return (
            <div key={index}>
                {
                    hist.image !== "" &&
                    <Row>
                        <img src={hist.image} alt={hist.image} style={{maxWidth: "500px", maxHeight: "300px"}}/>
                    </Row>
                }
                <Row>
                    <Col>
                        {prompt}
                        {/* {hist.message} */}
                        <MarkdownRenderer text={hist.message}/>
                        {func_msg}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {hist.speaker === 'c' && <br/>}
                    </Col>
                </Row>
                {hist.speaker === "c" && index !== HistoryList.length - 1 && <hr/>}
            </div>
        );
    });

    //////////////////// Mic, Speech Mode /////////////////////////
    // function base64ToBinary(base64String) {
    //     const binaryString = atob(base64String);
    //     const binaryData = new Uint8Array(binaryString.length);
    //     for (let i = 0; i < binaryString.length; i++) {
    //       binaryData[i] = binaryString.charCodeAt(i);
    //     }
    //     return binaryData;
    // }

    // function playAudio(AnswerMp3) {
    //     const audioBlob = new Blob([AnswerMp3], { type: 'audio/mpeg' });
    //     const audioUrl = URL.createObjectURL(audioBlob);
    //     audio.src = audioUrl;
    //     audio.play();
    // };

    async function sendDataToBackend(data) {
        try {
            setWaitAnswer(true);
            const formData = new FormData();
            if (Img) {
                formData.append("image", Img);
            }
            formData.append('audio', data);
            formData.append('user_id', UserInfo.user_id);
            formData.append('token', UserInfo.token);
            formData.append('status', JSON.stringify({
                //parking_remaining_sec: get_parking_remaining_sec()
                hw_state: props.HwState
            }));

            const res = await axios.post(`${USER_SERVER}/get_answer_speech`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setWaitAnswer(false);
            console.log('get_answer_speech --------->', res);
            if (res.data?.success) {
                await process_answer(res.data.data);
            } else {
                show_error(res);
            }

            console.log('Data sent successfully', res);
        } catch (error) {
            console.error('Error sending data to backend', error);
        }
    }

    async function onClickStart() {
        console.log('onClickStart=', RecStatus);
        if (RecStatus !== 'ready') {
            return;
        }

        setRecStatus('prepare_rec');
        try {
            vrecorder.current = new MyAudioRecorder();

            await vrecorder.current.openMic();
            await vrecorder.current.start_recording();
            setRecStatus('rec');
        } catch (err) {
            console.log(err.message);	// device가 없어서 error가 난 것으로 추정
            setRecStatus('ready');
        }
    }

    // stop recording
    async function onClickStop() {
        console.log('onClickStop=', RecStatus);
        if (RecStatus !== 'rec') {
            return;
        }
        setRecStatus('prepare_stop');
        await vrecorder.current.stop_recording();

        for (let k = 0; k < 30; k++) {
            const vsign_url = vrecorder.current.get_blob_url();
            if (vsign_url === 'too_short') {
                setRecStatus('ready');
                console.log('too short ---------------------------------', k);
                return;
            } else if (vsign_url) {
                console.log('check recorded data --------------------', k, vsign_url);
                const data = vrecorder.current.get_recorded_blob();
                await sendDataToBackend(data);
                setRecStatus('ready');
                console.log('======', vsign_url, k)
                return;
            }
            await sleep(100);
        }
        alert('Error');
    }

    async function onClickRec() {
        toggleTooltip();

        if (MicMode) {
            await onClickStop();
        } else {
            await onClickStart();
        }

        setMicMode(!MicMode);
    }

    const mic_text = (MicMode) ? 'Stop Mic' : 'Start Mic';
    const button_color = (RecStatus === 'rec') ? 'red' : 'blue';
    const mic_btn_disable = (RecStatus === 'prepare_rec' || WaitAnswer);

    const boxStyle = {
        border: '1px solid #ccc', // 경계선 추가
        borderRadius: '10px',     // 둥근 테두리 반경
        padding: '10px',          // 내부 여백
        textAlign: 'center',       // 텍스트 중앙 정렬
        height: '4.5em',                // 박스 높이 설정, 약 3줄 분량
        lineHeight: '1.5em',            // 한 줄의 높이 설정
        display: 'flex',                // Flexbox 사용
        alignItems: 'center',           // 세로 중앙 정렬
        justifyContent: 'center',        // 가로 중앙 정렬
        cursor: 'pointer'
    };

    const template_list = [
        '밤 11시 넘어서 영업하는 주변 식당을 알려줄래?',
        '졸린데 재미있는 얘기 하나 해줄래?',
        '운전석 창문 열어줘',
        '볼륨 좀 올려줘'
    ];

    async function onClickTemplate(idx) {
        await send_question(template_list[idx]);
    }

    function toggleTooltip() {
        setTooltipOpen(!TooltipOpen);
    };

    const imageRef = useRef();
    const [ImgUrl, setImgUrl] = useState("");
    const [Img, setImg] = useState();

    function MarkdownRenderer({text}) {
        return (
            <div>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {text}
                </ReactMarkdown>
            </div>
        );
    };

    function onClickStopPlaying() {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setWhilePlay(false);
        }
    };

    return (
        <div>
            {/* <Row gutter={16}>
                <Col span={6}>
                    <div style={boxStyle} onClick={() => onClickTemplate(0)}>{template_list[0]}</div>
                </Col>
                <Col span={6}>
                    <div style={boxStyle} onClick={() => onClickTemplate(1)}>{template_list[1]}</div>
                </Col>
                <Col span={6}>
                    <div style={boxStyle} onClick={() => onClickTemplate(2)}>{template_list[2]}</div>
                </Col>
                <Col span={6}>
                    <div style={boxStyle} onClick={() => onClickTemplate(3)}>{template_list[3]}</div>
                </Col>
            </Row> */}
            <Row
                style={{
                    marginTop: '20px',
                    maxHeight: "500px",
                    overflowY: "scroll"
                }}
                id={"hide-scroll"}
            >
                <Col>
                    {render_histlist}
                </Col>
            </Row>

            <div style={{
                border: `${ImgUrl === "" ? "none" : "1px solid black"}`,
                padding: "10px",
                borderRadius: "10px"
            }}>
                <Row hidden={ImgUrl === ""} justify={"center"}>
                    <img src={ImgUrl} alt={ImgUrl} style={{maxWidth: 500, maxHeight: 300}}/>
                </Row>
                <Row justify="center" align='middle'>
                    Input:
                    <Input
                        disabled={WaitAnswer}
                        value={Question}
                        onChange={(e) => {
                            setQuestion(e.currentTarget.value);
                        }}
                        onPressEnter={onClickSend}
                        style={{width: '85%', margin: 10}}
                    />
                </Row>
            </div>
            {WhilePlay ?
                <Row justify='center'>
                    <Button type='primary' onClick={onClickStopPlaying}> Stop </Button>
                </Row> :
                <Row justify="center" align='middle'>
                    <Col>
                        <input
                            ref={imageRef}
                            type={"file"}
                            multiple={false}
                            hidden={true}
                            accept={"image/jpeg,image/jpg,image/png,image/webp"}
                            onChange={async function (event) {
                                URL.revokeObjectURL(Img);
                                setImg();
                                setImgUrl("");
                                if (event.target.files.length > 0) {
                                    setImg(event.target.files[0]);
                                    setImgUrl(URL.createObjectURL(event.target.files[0]));
                                }
                            }}
                        />
                        <Button
                            disabled={WaitAnswer}
                            type='primary' onClick={() => imageRef.current.click()}
                            style={{margin: 10}}>
                            Upload Image
                        </Button>
                    </Col>
                    <Col>
                        {
                            WaitAnswer ? <Spin/> :
                                <Button disabled={WaitAnswer} type='primary' onClick={onClickSend} style={{margin: 10}}>
                                    Send Text
                                </Button>
                        }
                        또는&nbsp;
                    </Col>
                    <Col>
                        <Tooltip title='말을 마치고 눌러주세요' open={TooltipOpen}>
                            <Button type='primary' onClick={onClickRec} disabled={mic_btn_disable}
                                    style={{backgroundColor: button_color}}>{mic_text}</Button>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Button disabled={WaitAnswer} type='primary' onClick={onClickReset} style={{marginLeft: 30}}>Reset
                            and Restart</Button>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default withRouter(Chat)
