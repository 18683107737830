import React from 'react';
import {withRouter} from "react-router-dom";


function CarPicture({HwState, change_Hw_state}) {
    console.log("HwState", HwState);
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
            }}
        >
            <img
                src={"/images/map1.webp"}
                alt={"/car/car.png"}
                style={{
                    position: "absolute",
                    bottom: "100px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: "10px",
                    objectFit: "contain",
                    objectPosition: "center",
                    border: "1px solid black",
                }}
            />
        </div>
    )
}

export default withRouter(CarPicture)
