// set port of backend
const PORT                = 7011;     // for my private

// base url
const baseUrl = 'http://localhost:7011';                // my private local

//const USER_SERVER   = '/api';
const USER_SERVER = '/api/v001';

const REACT_APP_APPLE_CLIENT_ID="heraigirl.heraigirl.com"
const REACT_APP_APPLE_REDIRECT_URI="https://dev.herchatgpt.com"
const REACT_APP_GOOGLE_CLIENT_ID="562347218682-nsa5j871j9dheh2mhb13b30hndv5k447.apps.googleusercontent.com"

module.exports = {
    PORT,
    baseUrl,
    USER_SERVER,
    REACT_APP_GOOGLE_CLIENT_ID
}
