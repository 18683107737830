import React from "react";
import RightMenu from "./Sections/RightMenu";
import { Row, Col } from "antd";
import LogoNew from "../../../assets/images/zeezee.jpg";
import "./Sections/Navbar.css";

function NavBar() {
    return (

        <nav className="menu">
            <div className="menu-inner">
                <div className="container">
                    <Row justify="space-between " className="header-row" align="center">
                        <Col offset={2}>
                            <h1 style={{ fontSize: '2em', margin: '0.3em 0'  }}>
                                RED QUEEN DEFENSE AI
                            </h1>
                        </Col>
                        <Col className="right-menu">
                            <RightMenu mode="horizontal"/>
                        </Col>
                    </Row>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
